import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { Field } from "redux-form";
import FormField from "forms/FormField.js";

const FormFieldArray = ({
  fields,
  fieldKey = "",
  // pass a field key if your array is structure [{fieldKey: 1234}, {fieldKey: 4321}]
  // instead of ['someField', 'someField']
  label,
  placeholder,
  meta: { error },
  format,
  fieldNames,
  multipleFields,
  multiFieldNormalizing,
  multiFieldTypes,
  multiFieldFormat,
  multiFieldRequired,
  multiFieldValidation,
  normalize,
  validation,
  required,
  renderCondition = true,
  rows = false,
}) => {
  useEffect(() => {
    createInitialField().then(() => {
      if (multipleFields) {
        const numOfFieldsToAdd = fieldNames.length - 1;

        for (let i = 0; i < numOfFieldsToAdd; i++) {
          fields.push({});
        }
      }
    });
  }, []);

  const createInitialField = () =>
    new Promise((res) => {
      if (fields.length < 1) {
        fields.push();
      }
    });

  if (!renderCondition) {
    return null;
  }

  return (
    <div className="form-field-array">
      {fields.map((field, index) => (
        <div key={index} style={{ display: "flex", flexDirection: "row" }}>
          {multipleFields ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: rows ? "column" : "row",
                  width: "90%",
                  [rows ? "justifyContent" : "alignItems"]: "center",
                }}
              >
                {fieldNames.map((fieldName, idx) => {
                  return (
                    <Field
                      key={`${fieldName}-${idx}`}
                      name={`${field}.${fieldNames[idx]}`}
                      component={FormField}
                      label={
                        fieldName.slice(0, 1).toUpperCase() + fieldName.slice(1)
                      }
                      format={multiFieldFormat?.[idx] || format || ((x) => x)}
                      normalize={multiFieldNormalizing?.[idx]}
                      required={multiFieldRequired?.[idx] || required}
                      validate={multiFieldValidation?.[idx] || validation}
                      type={multiFieldTypes?.[idx] || "text"}
                      style={{ paddingRight: 10 }}
                      isReports
                    />
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexGrow: 1,
                }}
              >
                {fields.length > 1 && (
                  <Button
                    variant="primary"
                    onClick={() => fields.remove(index)}
                  >
                    X
                  </Button>
                )}
              </div>
            </>
          ) : (
            <Field
              name={fieldKey ? `${field}[${fieldKey}]` : field}
              type="text"
              component={FormField}
              removeField={
                fields.length > 1 ? () => fields.remove(index) : null
              }
              placeholder={placeholder}
              label={index === 0 ? label : `${label} #${index + 1}`}
              format={format}
              normalize={normalize}
              required={required}
              validate={validation}
            />
          )}
        </div>
      ))}

      {error && <li className="error">{error}</li>}
      <div className="add-form-control text-right">
        <Button variant="primary" onClick={() => fields.push()}>
          Add {label}
        </Button>
      </div>
    </div>
  );
};

FormField.propTypes = {
  fields: PropTypes.array,
  label: PropTypes.string,
  fieldNames: PropTypes.array,
  multipleFields: PropTypes.bool,
  multiFieldNormalizing: PropTypes.array,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  validation: PropTypes.func,
};

export default FormFieldArray;
